* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

%h1 { font-size: 2rem };
%h2 { font-size: 1.8rem };
%h3 { font-size: 1.5rem };

$color-text: #222;
$item-padding: 6px 0;

%tag {
  border: 1px solid #ccc;
  line-height: 1em;
  margin: 3px 8px 3px 0;
  padding: 2px 4px;
  white-space: nowrap;
}

h1 { @extend %h1; };
h2 { @extend %h2; };
h3 { @extend %h3; };

.action, input, select, textarea {
  border: 1px solid transparent;
  border-radius: 0;
  color: inherit;
  font: inherit;
  &:focus {
    border-color: #ccc;
  }
}

.action {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
}

.c_app {
  display: flex;
  flex-direction: column;

  /** Fallback for browsers without css variables */
  height: 100vh;
  max-height: 100vh;

  /**
   * Deal with mobile browser status bars.
   * Requires a css variable named --vh provided
   * by useViewportHeight() hook.
   */
  height: calc(var(--vh, 100vh));
  max-height: calc(var(--vh, 100vh));

  padding: 10px;
}

.c_category-detail,
.c_category-list,
.c_category-list-edit,
.c_entry-detail,
.c_entry-list {
  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    @extend %h2;
  }
}

.c_category-detail {
  &__header {
    input {
      width: 100%;
    }
  }
  &__tag {
    input {
      padding: $item-padding;
      width: 100%;
    }
  }
  &__new-tag {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    input {
      border-color: #ccc;
      flex-grow: 1;
    }
  }
}

.c_category-list {
  &__header {
    display: flex;
    flex-direction: row;
    :first-child {
      flex-grow: 1;
    }
    .action {
      line-height: 1em;
    }
  }
  &__item {
    .route-link {
      color: inherit;
      padding: $item-padding;
      text-decoration: none;
    }
  }
}

.c_entry-detail {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  > :not(:first-child) {
    margin: 10px 0;
  }
  &__header {
    .c_text-edit {
      width: 100%;
    }
  }
  &__date {
    display: block;
  }
  &__content {
    flex-grow: 1;
  }
  &__category-list {
    display: grid;
    grid-auto-columns: max-content;
    grid-gap: 10px;
    label {
      grid-column: 1;
    }
    .c_dropdown {
      grid-column: 2;
    }
  }
  &__actions {
    :first-child {
      margin-right: 10px;
    }
  }
}

.c_entry-list {
  &__header {
    display: flex;
    flex-direction: row;
    :first-child {
      flex-grow: 1;
    }
    .action {
      line-height: 1em;
    }
  }
  .c_search-box {
    margin-top: 10px;
  }
  &__entries {
    /* fallback for non css variable browsers */
    height: calc(100vh - 154px);
    max-height: calc(100vh - 154px);

    /* keep scroll area within vh for mobile with status bars */
    height: calc(var(--vh, 100vh) - 154px);
    max-height: calc(var(--vh, 100vh) - 154px);

    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; /* enable momentum scrolling on IOS */
  }
  &__entry {
    margin: 10px 0;
    .route-link {
      color: $color-text;
      display: flex;
      flex-direction: column;
      text-decoration: none;
    }
    .c_tag-list {
      margin: 10px 0;
    }
  }
  &__entry-date {
    @extend %h2;
  }
}

.c_nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  &__link {
    border-bottom: 2px solid transparent;
    color: inherit;
    padding: 10px;
    text-decoration: none;
    &.active {
      border-color: $color-text;
    }
  }
}

.c_search-box {
  display: flex;
  flex-direction: row;

  input {
    border: 1px solid #ccc;
    flex-grow: 1;
    padding: 6px;
    width: 100%;
  }
  button {
    border: 1px solid #ccc;
    padding: 6px;
  }
}

.c_tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &__tag {
    @extend %tag;
  }
}

.c_tag-list-editor {
  display: flex;
  flex-direction: column;
  &__tag-list.c_text-edit {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .c_tag-list-editor__tag {
    @extend %tag;
  }
}

.c_text-edit {
  display: inline-block;
}

.route-link {
  display: inline-block;
}